import { useCallback, useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, YAxis } from 'recharts';

import { useId } from '@/hooks/use-id';

import type { LineData } from '@/lib/spline';

const DAY = 1000 * 60 * 60 * 24;

type SplineChartProps = {
  data: LineData[];
  width?: number;
  height?: number;
  className?: string;
  strokeWidth?: number;
  color?: string;
  gradientOpacity?: number;
  lastValueGrid?: boolean;
  bottomOffset?: number;
  todayOpenPrice?: number;
};

export const SplineChart: React.FC<SplineChartProps> = ({
  data,
  width = 600,
  height = 240,
  className,
  strokeWidth = 2,
  color = '#606E83',
  gradientOpacity = 1,
  lastValueGrid = false,
  bottomOffset = 0.5,
  todayOpenPrice,
}) => {
  const backgroundId = `spline-background-${useId()}`;

  // To make sure the data is always sorted by x, we sort it here.
  const lineData = useMemo<LineData[]>(
    () => data.filter(([value]) => !!value).sort((a, b) => a[1] - b[1]),
    [data],
  );

  const yScale = useCallback(
    (value: number) => {
      const min = Math.min(...lineData.map(([v]) => v));
      const max = Math.max(...lineData.map(([v]) => v));

      const baseLine = height * bottomOffset;

      return ((value - min) / (max - min)) * (height - baseLine) + baseLine;
    },
    [height, lineData, bottomOffset],
  );

  const xScale = useCallback(
    (value: number) => {
      const xRange = [lineData[0][1], lineData[lineData.length - 1][1]];
      return ((value - xRange[0]) / (xRange[1] - xRange[0])) * width;
    },
    [width, lineData],
  );

  const chartData = lineData.map(([value, timestamp]) => ({
    date: timestamp,
    price: yScale(value),
  }));

  return (
    <AreaChart
      className={className}
      width={width}
      height={height}
      compact
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <defs>
        <linearGradient id={backgroundId} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={color} stopOpacity={gradientOpacity} />
          <stop offset="100%" stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <YAxis hide domain={[0, height]} />
      {lastValueGrid && (
        <CartesianGrid
          horizontalPoints={[height - yScale(lineData[lineData.length - 1][0])]}
          vertical={false}
          width={width}
          strokeDasharray="4,4"
          stroke={color}
          opacity={0.4}
          vectorEffect="non-scaling-stroke"
        />
      )}
      {todayOpenPrice && (
        <CartesianGrid
          horizontalPoints={[height - yScale(todayOpenPrice)]}
          vertical={false}
          width={width}
          strokeDasharray="4,4"
          stroke="#475364"
          opacity={0.4}
          vectorEffect="non-scaling-stroke"
        />
      )}
      <Area
        data={chartData}
        isAnimationActive={false}
        dataKey="price"
        type="natural"
        fill={`url(#${backgroundId})`}
        stroke={color}
        strokeWidth={strokeWidth}
        vectorEffect="non-scaling-stroke"
      />
      {/* GLYPH CIRCLE */}
      {todayOpenPrice && (
        <>
          <path
            d={`M ${xScale(Math.floor(Date.now() / DAY) * DAY)} ${
              height - yScale(todayOpenPrice)
            } l 0.0001 0`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={12}
            strokeLinecap="round"
            stroke="#475364"
          />
          <path
            d={`M ${xScale(Math.floor(Date.now() / DAY) * DAY)} ${
              height - yScale(todayOpenPrice)
            } l 0.0001 0`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={10}
            strokeLinecap="round"
            stroke="#171B25"
          />
        </>
      )}
    </AreaChart>
  );
};
